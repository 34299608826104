* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

.react-tabs {
  display: flex;
  margin-left: 36px;
  width: 470px;
  height: 400px;
  border: 1px solid #3c3e43;
  color: white;
  background: #1c1d1f;
}

.react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  width: 170px;
  margin: 0;
  padding: 0;
  color: white;
  background: #3c3e43;
}

.react-tabs__tab {
  height: 70px;
  list-style: none;
  padding: 12px 6px;
  cursor: pointer;
  color: #bbb;
}

.react-tabs__tab--selected {
  background: #1c1d1f;
  border-color: #1c1d1f;
  border-left: 4px solid #6eb800;
  color: white;
}

.react-tabs__tab-panel {
  display: none;
  width: 300px;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.react-tabs__tab {
  padding-left: 24px;
}

.react-tabs__tab--selected {
  padding-left: 21px;
}

.panel-content {
  text-align: center;
}

.ok {
  color: #00d300 !important;
}

.no {
  color: #f70000 !important;
}

::-webkit-scrollbar {
  width: 3px;
}

.modalBox {
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 450px;
  width: auto;
  max-width: 680px;
  overflow: auto;
  height: auto;
  max-height: 90vh;
  background: #e8e8e8;
  border: none;
  color: #fff;
  box-shadow: 5px 5px 10px 5px #eeeeee64;
  padding: 5px;
  border-radius: 5px;
  flex-direction: column;
  display: flex;
}

.text-field {
  width: 45%;
}

.btn-image {
  margin-right: 20px;
  height: 100%;
  display: flex;
  height: 60px;
}

.btn-image.ok {
  border: 1px solid #00fd00;
  border-radius: 5px;
}

.admin-comments {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.news_block {
  display: flex;
  padding-top: 80px;
  background-size: cover;
  min-height: 100vh;
}

@media screen and (max-width: 960px) {
  .modalBox {
    max-height: 70vh;
  }

  .admin-comments {
    flex-direction: column;
  }

  .admin-comments-text_field {
    margin: 10px !important;
    width: 250px;
  }
}

.news_card {
  max-width: 1200px;
}
.news_title {
  font-size: 24px;
  font-weight: 600;
}

.news_card_item {
  flex-wrap: nowrap;
}

@media screen and (max-width: 768px) {
  .news_card_item {
    flex-wrap: wrap;
  }
}
