.open{
    visibility: visible !important;
    opacity: 1 !important;
    transform: scale(1) !important;
}

.open svg{
    position: fixed;
    top: 20px;
    right: 20px;
    color:white;
    cursor: pointer;
    width: 40px;
    height: 40px;
}
