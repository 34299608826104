.slide{
   opacity: 0;
   display: flex;
   justify-content: center;
   align-items: center;
   transition-duration: 1s ease;
}

@media screen and (max-width : 960px) {
   .slide{
      flex-direction: column;
   }
}
.slide.active{
    opacity:1;
    width: 100%;
    transition-duration: 1s;
    transform:scale(1);
}