.social-sidebar{
    border-radius : 5px;
    white-space: nowrap;
    padding: 10px 10px;
    color: #f3f3f3;
    font-size: 3rem;
    outline:none;
    border:none;
    cursor:pointer;
    transition: 0.2s ease;
    text-decoration:none;
}
.social-sidebar:hover{
    color: #bf9f01;
    transition: 0.2s ease;
}

.social-sidebar-btns{
    position: absolute;
    
    left: 50%; 
    transform: translate(-50%, 0);
    bottom: 0;
    display: flex;
    align-items:center;
}

@media (orientation: landscape) {
    .social-sidebar{
        font-size: 2rem;
    }
}