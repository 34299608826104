.social {
  border-radius: 5px;
  white-space: nowrap;
  padding: 10px 10px;
  color: #b2904f;
  font-size: 26px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.2s ease;
  text-decoration: none;
}
.social:hover {
  color: #bf9f01;
  transition: 0.2s ease;
}

.social-btns {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 960px) {
  .social-btns {
    display: none;
  }
}
